import React, { Fragment } from 'react';

const faqs = [
  {
    question: 'how often do I host',
    answer: 'Members can expect to host once every 3 dinners attended. Special events do not count.'
  },
  {
    question: 'what are some of our special events',
    answer: (
      <Fragment>
        <p>
          The Board strives to arrange an All Club Party once a year during a month that is then not part of the hosting
          rotation. Other parties are organized based on member's suggestions and offers of venue hosting.
        </p>
        <p>
          If any club member has ideas for out of area wine/dining trips or experiences, please contact Membership
          Chairperson, <a href='mailto:rhondaloves2paint@gmail.com'>Rhonda Mills</a>.
        </p>
      </Fragment>
    )
  },
  {
    question: 'what if I go on vacation or some other conflict arises and I cannot host',
    answer: (
      <Fragment>
        <p>
          Inform Rhonda as soon as you can! These things happen and we understand. However, she needs to be informed as
          early as possible so that another host can be scheduled.
        </p>
        <br />
        <strong>Sample rotation schedule:</strong>
        <p>
          Here's an example beginning in May where you are the host. A conflict arises in September and you cannot host
          so you call Rhonda! Your next hosting month would be November.
        </p>
        <div className='example-schedule'>
          <table className='pure-table'>
            <thead>
              <tr>
                {['May', 'June', 'July', 'August', 'September', 'October', 'November'].map((m, idx) => (
                  <td key={idx}>{m}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='host'>You are the Host</td>
                <td>You bring 2 bottles of wine.</td>
                <td>Summer Party</td>
                <td>You bring one bottle of wine and dessert</td>
                <td>
                  You <strong>cannot</strong> host due to other commitments.
                </td>
                <td className='host'>You are the Host</td>
                <td>You bring 2 bottles of wine.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Fragment>
    )
  },
  {
    question: 'can I invite other friends to dinner',
    answer:
      'Yes, if you are the host, you can invite another couple as long as your dinner club guests are informed. Please be sure that you or your guests can provide additional wine or appetizer and etc. for the additions.'
  },
  {
    question: 'what happens if I volunteer to host out of turn when asked',
    answer:
      'Your next hosting will be scheduled so that you AVERAGE every 3 dinners attended. If you are confused contact Rhonda. She likes volunteers and tries to make them happy.'
  }
];

const QandAView = () => {
  return (
    <Fragment>
      <img alt='cake' className='bordered-image page-highlight-image' src='/images/chocolate-cake.png' />

      <p>On this page you'll find a more detailed explanation of how the host rotation is organized.</p>
      <p>
        If you have any questions about this, please contact{' '}
        <a href='mailto:rhondaloves2paint@gmail.com'>Rhonda Mills</a> for more details.
      </p>

      {faqs.map((faq, idx) => (
        <div key={idx}>
          <h3>
            {faq.question.slice(0, 1).toUpperCase()}
            {faq.question.slice(1)}?
          </h3>
          {faq.answer}
        </div>
      ))}
    </Fragment>
  );
};

export default QandAView;
