import Amplify from 'aws-amplify';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './App.scss';
import aws_exports from './aws-exports';
import NavBar from './components/NavBar';
import Routes from './components/Routes';
import { toggleMobileMenu, updateBrowserLocation } from './modules/app/actions';

Amplify.configure({
  ...aws_exports,
  API: { endpoints: [{ name: 'SCDCAPI', endpoint: 'https://api.santacruzdinnerclub.com' }] }
});

const mapDispatchToProps = (dispatch) => ({
  onHistoryChange: (location) => dispatch(updateBrowserLocation(location)),
  onMenuToggle: () => dispatch(toggleMobileMenu())
});

class App extends PureComponent {
  componentDidMount() {
    this.props.history.listen(this.props.onHistoryChange);
  }

  render() {
    const { location } = this.props;

    return (
      <div className='pure-g page-container'>
        <div className='pure-u-1'>
          <div className='pure-g'>
            <div className='pure-u-1 pure-u-md-1-8'>
              <div className='nav-header'>
                <span className='mobile-menu-toggle' onClick={this.props.onMenuToggle}>
                  Menu
                </span>
              </div>
            </div>

            <div className='pure-u-1 pure-u-md-7-8 header'>
              <span className='title'>Santa Cruz Dinner Club</span>
              <span className='subtitle'>a club for people who love to cook, entertain, and meet new friends</span>
            </div>
          </div>
        </div>

        <NavBar />

        <div className={`pure-u-1 pure-u-md-7-8 page-content ${location.pathname.slice(1) || 'home'}`}>
          <Routes />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, mapDispatchToProps)(App));
