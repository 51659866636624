import React, { Fragment } from 'react';

const HomeView = () => {
  return (
    <Fragment>
      <div className='cartoon-introduction'>
        <div className='motto-stack'>
          <h3>Do you enjoy preparing fine foods as much as sharing them with friends?</h3>
          <h3>Do you seek out animated conversations with friends old and new?</h3>
          <h3>
            <i>You no longer have to travel great distances to find fine food and wine with good company.</i>
          </h3>
        </div>

        <img alt='' className='bordered-image cartoon' src='/images/place-setting.png' />
      </div>

      <div className='welcome-text'>
        <h2>Join us!</h2>
        <p>
          Become a member of the <strong>Santa Cruz Dinner Club</strong> and connect with others right here in your
          community who also enjoy good food and good company.
        </p>
        <br />
        <p>
          See our new member newsletter by{' '}
          <a href='/assets/newsletter.pdf' target='_blank'>
            clicking here
          </a>
          !
        </p>
        <h2>Contact our membership chair:</h2>
        <h3>
          <a href='mailto:info@santacruzdinnerclub.com'>Club Info Request</a>
        </h3>
      </div>

      <div className='quote-container'>
        <div className='quote'>
          <p>People who love to eat are always the best people.</p>
          <p>- Julia Child</p>
        </div>
      </div>
    </Fragment>
  );
};

export default HomeView;
