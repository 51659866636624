import { Authenticator, SignIn, SignOut } from 'aws-amplify-react';
import React from 'react';

const LoginView = () => (
  <Authenticator hideDefault={true}>
    <SignIn />
    <SignOut />
  </Authenticator>
);

export default LoginView;
