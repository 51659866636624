import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { refreshDirectory } from '../modules/app/actions';
import { getDirectory } from '../modules/app/selectors';

const mapStateToProps = (state) => ({
  members: getDirectory(state)
});

const mapDispatchToProps = (dispatch) => ({
  refresh: () => dispatch(refreshDirectory())
});

class DirectoryView extends Component {
  componentDidMount() {
    this.props.refresh();
  }

  getFirstName = ({ name }) => /^([^\s]+)/.exec(name)[1];

  renderAddress = (address) => <td>{this.renderStringToParagraphs(address)}</td>;

  renderAllergies = (person) => (
    <Fragment>
      {person.food.allergies && person.food.allergies.length > 0 && (
        <div className='allergies'>
          <p className='name'>{this.getFirstName(person)}</p>
          {person.food.allergies.map((allergy, idx) => (
            <p key={idx}>{allergy}</p>
          ))}
        </div>
      )}
    </Fragment>
  );

  renderContactInfo = (person) => (
    <Fragment>
      {person.contact && (
        <div className='contact-info'>
          <p className='name'>{this.getFirstName(person)}</p>
          {person.contact.email && (
            <p>
              <a href={`mailto:${person.contact.email}`}>{person.contact.email}</a>
            </p>
          )}
          {person.contact.phone && <p>{person.contact.phone}</p>}
        </div>
      )}
    </Fragment>
  );

  renderDislikes = (person) => (
    <Fragment>
      {person.food.dislikes && person.food.dislikes.length > 0 && (
        <div className='dislikes'>
          <p className='name'>{this.getFirstName(person)}</p>
          {person.food.dislikes.map((dislike, idx) => (
            <p key={idx}>{dislike}</p>
          ))}
        </div>
      )}
    </Fragment>
  );

  renderFavorites = (person) => (
    <Fragment>
      {person.food.favorites && person.food.favorites.length > 0 && (
        <div className='favorites'>
          <p className='name'>{this.getFirstName(person)}</p>
          {person.food.favorites.map((favorite, idx) => (
            <p key={idx}>{favorite}</p>
          ))}
        </div>
      )}
    </Fragment>
  );

  renderPerson = (person) => (
    <td width='200px'>
      {person && (
        <div className='person-wrapper' title={person.bio}>
          <span className='name'>{person.name}</span>

          {person.pic && <img alt={person.name} src={`/images/${person.pic}`} />}

          <p className='shortbio'>{person.shortbio}</p>
        </div>
      )}
    </td>
  );

  renderStringToParagraphs = (input) => {
    return input.split('\n').map((line, idx) => <p key={idx}>{line || '\u00A0'}</p>);
  };

  render() {
    const { members } = this.props;

    return (
      <Fragment>
        <p style={{ textAlign: 'center' }}>
          Be sure to review{' '}
          <span style={{ color: 'red' }}>
            <strong>allergies</strong>
          </span>{' '}
          and foods that a member will not eat.
        </p>

        <table className='pure-table pure-table-bordered' style={{ marginTop: '1rem', width: '100%' }}>
          <thead>
            <tr>
              <td />
              <td />
              <td width='180px'>Address / Phone</td>
              <td>Contact Information</td>
              <td>Allergies</td>
              <td>Dislikes</td>
              <td>Favorites</td>
            </tr>
          </thead>
          <tbody>
            {members.map((entry, idx) => (
              <tr key={idx}>
                {this.renderPerson(entry.person1, !entry.person2)}
                {this.renderPerson(entry.person2)}
                {this.renderAddress(entry.address)}

                <td>
                  {entry.person1 && this.renderContactInfo(entry.person1)}
                  {entry.person2 && this.renderContactInfo(entry.person2)}
                </td>

                <td>
                  {entry.person1 && this.renderAllergies(entry.person1)}
                  {entry.person2 && this.renderAllergies(entry.person2)}
                </td>

                <td>
                  {entry.person1 && this.renderDislikes(entry.person1)}
                  {entry.person2 && this.renderDislikes(entry.person2)}
                </td>

                <td>
                  {entry.person1 && this.renderFavorites(entry.person1)}
                  {entry.person2 && this.renderFavorites(entry.person2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DirectoryView);
