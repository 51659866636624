export const ActionTypes = {
  BrowserLocationChange: 'app/BrowserLocationChange',
  RefreshAssignments: 'app/RefreshAssignments',
  RefreshDirectory: 'app/RefreshDirectory',
  SetAssignments: 'app/SetAssignments',
  SetDirectory: 'app/SetDirectory',
  ToggleMobileMenu: 'app/ToggleMobileMenu',
  UserLoggedIn: 'app/UserLoggedIn',
  UserLoggedOut: 'app/UserLoggedOut'
};

export const refreshAssignments = () => ({
  type: ActionTypes.RefreshAssignments
});

export const refreshDirectory = () => ({
  type: ActionTypes.RefreshDirectory
});

export const setAssignments = (data) => ({
  type: ActionTypes.SetAssignments,
  data
});

export const setDirectory = (data) => ({
  type: ActionTypes.SetDirectory,
  data
});

export const toggleMobileMenu = (forceHide = false) => ({
  type: ActionTypes.ToggleMobileMenu,
  forceHide
});

export const updateBrowserLocation = (location) => ({
  type: ActionTypes.BrowserLocationChange,
  location
});

export const userLoggedIn = () => ({
  type: ActionTypes.UserLoggedIn
});

export const userLoggedOut = () => ({
  type: ActionTypes.UserLoggedOut
});
