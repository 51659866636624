import { API, Auth } from 'aws-amplify';
import { DateTime } from 'luxon';
import { call, put, takeLatest } from 'redux-saga/effects';
import history from '../history';
import { ActionTypes, setAssignments, setDirectory } from './actions';

const fetchData = async (route) => {
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    return await API.get('SCDCAPI', route, { headers: { Authorization: `Bearer ${token}` } });
  } catch (err) {
    console.error(err);
    return {};
  }
};

function* navigateOnLogin() {
  yield call(history.push, '/info');
}

function* refreshAssignmentList() {
  const data = yield call(fetchData, '/assignments');
  const list = data.sort((a,z) => {
    const aDate = DateTime.fromISO(a.date);
    const zDate = DateTime.fromISO(z.date);

    if (aDate < zDate)
      return 1;
    else if(aDate > zDate)
      return -1;

    return 0;
  })

  yield put(setAssignments(list));
}

function* refreshMemberDirectory() {
  const data = yield call(fetchData, '/members');
  const list = data.sort((a, b) => {
    const lastNamePattern = /^.+\s(\w+)$/i;

    try {
      const aName = lastNamePattern.exec(a.person1.name)[1];
      const bName = lastNamePattern.exec(b.person1.name)[1];

      return aName.localeCompare(bName);
    } catch {
      return 0;
    }
  });

  yield put(setDirectory(list));
}

export function* appSagas() {
  yield takeLatest(ActionTypes.RefreshAssignments, refreshAssignmentList);
  yield takeLatest(ActionTypes.RefreshDirectory, refreshMemberDirectory);
  yield takeLatest(ActionTypes.UserLoggedIn, navigateOnLogin);
}
