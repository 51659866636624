import classnames from 'classnames';
import { DateTime } from 'luxon';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { refreshAssignments } from '../modules/app/actions';
import { getAssignments } from '../modules/app/selectors';
import '../styles/pages/assignments.scss';

const mapStateToProps = (state) => ({
  assignments: getAssignments(state)
});

const mapDispatchToProps = (dispatch) => ({
  refresh: () => dispatch(refreshAssignments())
});

class AssignmentsView extends Component {
  componentDidMount() {
    this.props.refresh();
  }

  renderAssignment = (assignment, field) => (
    <td>
      <p>
        {Array.isArray(assignment[field])
          ? assignment[field].map((val) => (
              <span key={val} style={{ display: 'block' }}>
                {val}
              </span>
            ))
          : assignment[field]}
      </p>
    </td>
  );

  render() {
    const { assignments } = this.props;

    return (
      <Fragment>
        <div style={{ textAlign: 'center' }}>
          <p>Hosts are responsible for contacting their guests.</p>
          <p>
            For problems with the schedule, please contact Rhonda at{' '}
            <a href='mailto:rhondaloves2paint@gmail.com'>rhondaloves2paint@gmail.com</a>.
          </p>
          <br />
          <p>We have included earlier assignments so you can see when your turn at hosting is coming up.</p>
          <p>Normally you will host every 3rd time you attend a dinner.</p>
        </div>

        <table className='pure-table'>
          <thead>
            <tr>
              <td>Date</td>
              <td>Host</td>
              <td>Wine</td>
              <td>Dessert/Wine</td>
              <td>Appetizer/Wine</td>
            </tr>
          </thead>
          <tbody>
            {assignments.map((entry, entryIdx) => (
              <Fragment key={entryIdx}>
                {entry.entries.map((assignment, idx) => (
                  <Fragment key={idx}>
                    <tr
                      className={classnames({
                        current: entryIdx === 0,
                        secondary: entryIdx % 2 === 0,
                        tertiary: entryIdx % 3 === 0,
                        quaternary: entryIdx % 4 === 0
                      })}
                    >
                      <td>{idx === 0 && DateTime.fromISO(entry.date).toFormat('DD')}</td>
                      {this.renderAssignment(assignment, 'host')}
                      {this.renderAssignment(assignment, 'wine')}
                      {this.renderAssignment(assignment, 'dessert')}
                      {this.renderAssignment(assignment, 'appetizer')}
                    </tr>
                  </Fragment>
                ))}
                <tr className='spacer'>
                  <td className='spacer' colSpan='5' />
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentsView);
