import { ActionTypes } from './actions';

const initialState = {
  assignments: [],
  loggedIn: false,
  members: [],
  mobileMenuVisible: false
};

export default (state = initialState, action = {}) => {
  if (state === undefined) {
    return { ...initialState };
  }

  switch (action.type) {
    case ActionTypes.SetAssignments:
      return {
        ...state,
        assignments: action.data
      };
    case ActionTypes.SetDirectory:
      return {
        ...state,
        members: action.data
      };
    case ActionTypes.ToggleMobileMenu:
      return {
        ...state,
        mobileMenuVisible: action.forceHide ? false : !state.mobileMenuVisible
      };
    case ActionTypes.UserLoggedIn:
      return {
        ...state,
        loggedIn: true
      };
    case ActionTypes.UserLoggedOut:
      return {
        ...state,
        loggedIn: false
      };
    default: {
      return { ...state };
    }
  }
};
