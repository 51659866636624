import { Auth } from 'aws-amplify';
import classnames from 'classnames';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getMobileMenuVisible, isLoggedIn } from '../modules/app/selectors';

const navItems = {
  '': 'Home',
  '/info': 'Club Information',
  '/dinner': 'Dinner Assignments',
  '/directory': 'Member Directory',
  '/faq': 'Dinner Rotation FAQ',
  '/bylaws': 'By-Laws'
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
  mobileMenuVisible: getMobileMenuVisible(state)
});

const onLogoutClick = () => {
  Auth.signOut();
};

const NavBar = ({ isLoggedIn, location, mobileMenuVisible }) => {
  return (
    <nav className={'pure-u-1 pure-u-md-1-8'}>
      <div className={classnames('pure-menu menu', { toggled: mobileMenuVisible })} role={'navigation'}>
        <ul className={'pure-menu-list'}>
          {isLoggedIn ? (
            <Fragment>
              {
                Object.entries(navItems).map(([route, item]) => {
                  const extraClasses = [];
                  let title = item;

                  if (location.pathname === route) {
                    extraClasses.push('active');
                  }

                  if (Array.isArray(item)) {
                    title = item[0];
                    extraClasses.push(item.slice(1));
                  }

                  return (
                    <li key={route} className={`pure-menu-item ${extraClasses.join(' ')}`}>
                      <Link to={route}>{title}</Link>
                    </li>
                  );
                })
              }

              <li className='pure-menu-item'>
                <a href='/' onClick={onLogoutClick}>Sign Out</a>
              </li>
            </Fragment>
          ) : (
            <li className='pure-menu-item'>
              <Link to='/login' rel='nofollow'>Member Login</Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default withRouter(connect(mapStateToProps)(NavBar));
