// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-west-2',
  aws_content_delivery_bucket: 'santacruzdinnerclub.com',
  aws_content_delivery_bucket_region: 'us-west-2',
  aws_content_delivery_url: 'https://d1g3ooa0dq9z8e.cloudfront.net',
  aws_cognito_identity_pool_id: 'us-west-2:6568b305-3b75-481b-b1de-2b2ce361d948',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_0I6RJGkU6',
  aws_user_pools_web_client_id: '4n8rv9vvsgnmvaqmh1bfak6ts9'
};

export default awsmobile;
