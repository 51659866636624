import { Authenticator, SignIn } from 'aws-amplify-react';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AssignmentsView from '../containers/Assignments';
import ByLawsView from '../containers/ByLaws';
import ClubInfoView from '../containers/ClubInfo';
import DirectoryView from '../containers/Directory';
import Home from '../containers/Home';
import LoginView from '../containers/Login';
import QandAView from '../containers/QandA';

const PrivateRouteComponent = ({ authState, children }) => {
  if (authState === 'signedIn') {
    return children;
  }

  return null;
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Authenticator hideDefault={true}>
          <SignIn />

          <PrivateRouteComponent>
            <Component {...props} />
          </PrivateRouteComponent>
        </Authenticator>
      )}
    />
  );
};

const routes = ({ childProps }) => (
  <Switch>
    {/* Nav routes */}
    <Route path='/' exact component={Home} props={childProps} />
    <Route path='/login' exact component={LoginView} props={childProps} />
    <PrivateRoute path='/info' exact component={ClubInfoView} props={childProps} />
    <PrivateRoute path='/dinner' exact component={AssignmentsView} props={childProps} />
    <PrivateRoute path='/directory' exact component={DirectoryView} props={childProps} />
    <PrivateRoute path='/faq' exact component={QandAView} props={childProps} />
    <PrivateRoute path='/bylaws' exact component={ByLawsView} props={childProps} />

    <Route
      component={() => (
        <div className={'404'}>
          <h3>404 page not found</h3>
        </div>
      )}
    />
  </Switch>
);

export default routes;
