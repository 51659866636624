import React, { Fragment } from 'react';

const ByLawsView = () => {
  return (
    <Fragment>
      <img alt='tomato-dish' className='bordered-image page-highlight-image' src='/images/tomato-dish.png' />

      <p>
        <b>I. Name</b>
      </p>
      <p>Name of the organization shall be Santa Cruz Dinner Club.</p>

      <p>
        <b>II. Mission Statement</b>
      </p>
      <p>
        To enthusiastically celebrate the preparation and enjoyment of good food, to create new and enduring
        friendships, and to offer a warm welcome to new members.
      </p>

      <p>
        <b>III. Board Officers</b>
      </p>
      <p>
        The officers of the club Board shall manage all club business including budget/finances, promotion (advertising
        and website), membership and special events. If a Board officer vacates their position during the year,
        nominations will open and an interim vote will take place when called by the Secretary.
      </p>

      <p>
        <b>IV. Dinner Club Principles and Guidelines</b>
      </p>
      <p>
        1. Dinners are held the first Sunday of each month or as listed on the SCDC website. Dates have been shifted if
        they conflict with holidays as needed. Dinners begin at 5 pm. When assignments are made, the host and guests can
        make changes to the date (to a Saturday, or a different date) as well as the start time if everyone agrees.
        These changes are optional and to be arranged without needing to contact the Membership Chair (Rhonda).
      </p>
      <p>
        2. Hosts provide 2 or more appetizers, soup or salad, and the entree (with two sides). All food must be
        homemade.
      </p>
      <p>
        3. The second pair of guests will bring 2 bottles of high-quality wine (if unsure, we suggest that wine be
        valued at $25+). Most importantly we recommend wine be appropriate for very special dinners, wine that honors
        the fine meal being prepared by the hosts.
      </p>
      <p>4. The third pair of guests are to bring a homemade dessert plus one bottle of high-quality wine.</p>
      <p>
        5. If the dinner grouping includes 8 guests, the fourth pair of guests are to bring one appetizer and one bottle
        of high-quality wine. The host will still provide the 2+ appetizers.
      </p>
      <p>
        6. This dinner club is for those who value fine dining and enjoy cooking. The goal is for us to be challenged to
        cook well but no one expects us to be top chefs. The point is to aim for quality but we all understand if not
        every meal is a resounding success. Hosts will find that they spend a lot of time and effort in the kitchen, but
        the reward is that we will all enjoy wonderful meals and the effort is reciprocated when others host. These
        guidelines help ensure hosts that put significant work into their meal enjoy the same effort when attending
        other dinners.
      </p>
      <p>7. Dress: smart casual.</p>
      <p>
        8. Members must be current as far as their Covid vaccination status. If you are feeling ill or you have recently
        been exposed to someone who is Covid positive, we ask that you abstain from attending your dinner assignment and
        inform your host about the situation.
      </p>

      <p>
        <b>V. Club Membership</b>
      </p>
      <p>
        Membership to the Santa Cruz Dinner Club is open to anyone living in the San Lorenzo Valley towns, Santa Cruz,
        Soquel, Capitola and Aptos regardless of gender, race, religion, ethnicity or sexual preference. Members must be
        a minimum of 21 years of age with no maximum age.
      </p>
      <p>
        Prior to full Membership initiation, the following procedure will be followed to assure that there is no
        misunderstanding about what the Club is all about:
      </p>
      <p>
        <b>A) </b>
        The Membership Chair will meet the prospective New Member(s) at their home in order to see firsthand their
        living style and to ascertain that the home has accessibility to members who have mobility challenges.
      </p>
      <p>
        <b>B) </b>
        The Membership Chair will ascertain as well as she can their level of cooking skill.
      </p>
      <p>
        <b>C) </b>
        Prospective New Members will join an introductory dinner to experience the dinner club, bringing wine and an
        appetizer to showcase their cooking talent and to see if they are a good fit. If they decide to join, they will
        then pay their annual membership dues and join the standard dinner club rotation
      </p>
      <p>
        The Membership Chair will make sure that as a New Member, they will be joined by a Board member at their first
        hosting dinner.
      </p>
      <p>
        Prospective New Member(s) will have sent to them a welcome letter which will read “Dear Prospective Members”.
        They will also receive a copy of the By-Laws and General Hosting Information which they will be asked to read
        and sign in agreement.
      </p>

      <p>
        <b>VI. Membership Expulsion</b>
      </p>
      <p>
        Rarely there may be complaints lodged against a Member serious enough to warrant possible expulsion from the
        Club. The Membership Chair will assess the validity of the complaints. The Membership Chair will personally
        discuss the problem with that Member. If warranted the Membership Chair will then bring the situation and
        complaint against the Member before the Board for discussion. A vote will be taken on how to proceed. If the
        vote is to expel, that Member will be asked to leave the Membership in a mailed letter.
      </p>
      <p>Refund of Membership dues will be granted if Membership has been held for less than 3 months.</p>

      <p>
        <b>VII. Guest Protocol</b>
      </p>
      <p>
        In order to encourage new membership and to showcase what a Club dinner is like, hosts may invite up to two
        non-member guests to their dinner. Before finalizing the invitation, hosts must notify their Club assigned
        guests to request their approval. Club guests may choose to deny approval for any reason. If approved, the
        non-member guest(s) must bring one bottle of quality wine to their hosts' dinner.
      </p>
    </Fragment>
  );
};

export default ByLawsView;
