import { Auth, Hub } from 'aws-amplify';
import { userLoggedIn, userLoggedOut } from './modules/app/actions';

export default class AmplifyBridge {
  constructor(store) {
    this.store = store;

    Hub.listen('auth', this, 'AmplifyBridge');
    this.checkUser();
  }

  checkUser = () => {
    Auth.currentSession()
      .then((user) => {
        this.store.dispatch(userLoggedIn());
      })
      .catch((err) => {
        this.store.dispatch(userLoggedOut());
      });
  };

  onHubCapsule = (capsule) => {
    this.checkUser();
  };
}
