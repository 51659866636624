import classnames from 'classnames';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const dinnerDates = [
  {
    year: 2020,
    dates: [
      'January 5',
      'February 9',
      'March 1',
      'April 5',
      'May 3',
      'June 7',
      'July 12',
      'August 9',
      'September 13',
      'October 4',
      'November 8',
      'December 6'
    ]
  },
  {
    year: 2022,
    dates: [
      'January 9',
      'February 6',
      'March 6',
      'April 3',
      'May 1',
      'June 5',
      'July 10',
      'August 7',
      'September 11',
      'October 2',
      'November 6',
      'December 4'
    ]
  },
  {
    year: 2023,
    dates: [
      'January 8',
      'February 5',
      'March 5',
      'April 2',
      'May 7',
      'June 4',
      'July 9',
      'August 6',
      'September 10',
      'October 1',
      'November 5',
      'December 3'
    ]
  },
  {
    year: 2024,
    dates: [
      'January 7',
      'February 4',
      'March 3',
      'April 7',
      'May 5',
      'June 2',
      'July 7',
      'August 4',
      'September 8',
      'October 6',
      'November 3',
      'December 8'
    ]
  }
];

const ClubInfoView = () => (
  <Fragment>
    <img alt='gathering' className='bordered-image page-highlight-image' src='/images/dinner-settings.png' />

    <h3>Members</h3>
    <p>
      Click <Link to='/directory'>here</Link> to see a list of our members.
    </p>

    <h3>Organization</h3>
    <p>
      Once each month, a fine quality homemade dinner will be prepared in the homes of several Dinner Club members.
      Though members' allergies and likes/dislikes will be respected, no strictly vegetarian/vegan meals will be served.
    </p>
    <p>
      Each home will have three (3) couples in attendance whose number will include the Host who will be that evening's
      Chef.
    </p>
    <p>
      Included in this meal will be two or more appetizers, soup or salad, the entree, and sides (starch/vegetables).
      Optional is bread, butter, and coffee, which will also be part of the Host's responsibility.
    </p>
    <p>The remaining couples will bring as follows:</p>
    <ul>
      <li>Second couple - homemade dessert plus one (1) quality bottle of wine</li>
      <li>Third couple - two (2) quality bottles of wine that complement the Chef's menu</li>
      <li>In rare cases there may be a fourth couple who shall bring one appetizer plus one (1) bottle of wine</li>
    </ul>
    <p>
      This dinner formula provides for the cost and meal preparation to be divided equally among the participating
      couples.
    </p>

    <h3>Dinner Assignments</h3>
    <p>
      Click <Link to='/dinner'>here</Link> to see the current assignments.
    </p>
    <ol>
      <li>
        Groups will rotate throughout the year in order that everyone gets to meet different couples in a different home
        each month.
      </li>
      <li>Each member couple will host approximately three (3) dinners each year according to the rotation chart.</li>
    </ol>

    <div className='dinner-dates-container'>
      {dinnerDates
        .sort((a, b) => b.year - a.year)
        .map((dd) => (
          <table
            className={classnames('pure-table pure-table-bordered', {
              'previous-year': dd.year !== new Date().getFullYear()
            })}
            key={dd.year}
          >
            <thead>
              <tr>
                <td>
                  <h4 style={{ margin: 0 }}>{dd.year} Club Dinner Dates</h4>
                </td>
              </tr>
            </thead>
            <tbody>
              {dd.dates.map((date, idx) => (
                <tr key={idx}>
                  <td>{date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
    </div>

    <h3>Who Can Join?</h3>
    <p>Married couples, singles or paired friends who enjoy gourmet dining.</p>

    <h3>Who Must Be Excluded?</h3>
    <p>Anyone under the age of 21 as liquor will be served.</p>

    <h3>Special Events</h3>
    <p>
      During the year, the Club will have at least one General Membership Meeting plus other events all-member events as
      set up by the Board.
    </p>

    <h3>Membership Dues</h3>
    <p>
      To partially fund special events and to maintain our website, membership dues will be collected annually. Checks
      are to be made out to "Santa Cruz Dinner Club" and mailed to the treasurer, or as arranged by the treasurer.
    </p>

    <h3>Dress Code</h3>
    <p>
      Dress appropriate for the occasion. Levis and athletic wear ONLY for outdoor picnics & barbecues should they
      occur.
    </p>
  </Fragment>
);

export default ClubInfoView;
